<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                   Search
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Search
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <h5>Search Result for {{info}}</h5>
            <div class="table-responsive">
              <h5>Candidate</h5>
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table v-if="! loading" class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">	Surname</th>
                  <th class="px-3">	First name</th>
                  <th class="px-3">Enrolment info</th>
                  <th class="px-3">Status </th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="candidates.length != 0">
                  <tr v-for="(item, index) in candidates" :key="index">
                    <td class="px-3">
                      <span class=" font-weight-bolder mb-1">
                        {{item.last_name }}
                      </span>
                      <br>

                      <a class="text-primary font-weight-bolder text-hover-primary mb-1"  @click="getCandidateSummary(item.exam_key)"  v-copy="item.exam_key">
                        {{item.exam_key}}
                      </a>
                      <div>
                        <span class="badge badge-info mr-1 " v-if="item.product_type">
                            {{item.product_type_name}}
                        </span>
                      </div>


                    </td>
                    <td class="px-3">
                      <span class="text-dark-75 text-hover-primary mb-1" v-copy="item.first_name">
                        {{item.first_name }}
                      </span>
                    </td>


                    <td class="px-3">

                      {{item.exam_name}} <br/>

                      <span v-if="item.product_type == 'practical_exam'" v-html="item.additional_info"></span>
                    </td>
                    <td class="px-3">

                      <div v-html="item.enrolment_status_badge" class="mb-2"></div>
                      <div v-html="item.exam_status_badge" class="mb-2"></div>
                      <div v-if="item.check_refund_support_ticket_status">
                                    <span class="block px-5 text-center ameb__bg-orange rounded" v-if=" item.check_refund_support_ticket_status=='Pending'">
                            Pending Refund
                        </span>
                        <span class="block px-5 text-center ameb__bg-blue text-white rounded badge-assessed" v-if=" item.check_refund_support_ticket_status=='Completed'">
                                Refund Completed
                            </span>
                      </div>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  @click="getCandidateSummary(item.exam_key)" class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-eye</v-icon>
                                                </span>
                                <span class="navi-text">Candidate details</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="viewAccountHolderInfo(item.owner_id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-user</v-icon>
                                                </span>
                                <span class="navi-text">Enroller profile</span>
                              </a>
                            </b-dropdown-text>

                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td class="text-center" colspan="6">
                    No enrolment available for this search
                  </td>
                </tr>
                </tbody>
              </table>
              <b-pagination
                  :disabled="loading"
                  v-if="candidates.length > 0"
                  class="pull-right mt-7"
                  @input="searchCandidate"
                  v-model="candidate_page"
                  :total-rows="candidate_total"
                  :per-page="candidate_perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <h5>User</h5>
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table"  v-if="!isLoading">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Surname</th>
                  <th class="px-3">First name</th>
                  <th class="px-3">UniqueID </th>
                  <th class="px-3">Type</th>
                  <th class="px-3">Email</th>
                  <th class="px-3">Phone/mobile</th>
                  <th class="px-3">SCORE</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="users.length > 0" v-for="item in users">
                    <td class="px-3">

                      <a @click="viewAccountHolderInfo(item.id)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.last_name }}
                      </a>
                      <div>
                        <span class="badge badge-role mr-1 " v-if="item.role_text">
                          {{ item.role_text}}
                        </span>
<!--                        <span class="badge badge-success"-->
<!--                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"-->
<!--                        >{{ item.status_text }}</span>-->
<!--                        <span class="badge badge-info ml-1">
                          {{ item.total_candidate}}
                        </span>-->
                      </div>
                    </td>
                    <td class="px-3">

                      <span class="text-dark-75 mb-1" v-copy="item.first_name">
                        {{item.first_name }}
                      </span>
                    </td>
                    <td class="px-3">
                      <span  class="font-weight-bolder text-dark-75"  v-copy="item.unique_identifier">
                        {{item.unique_identifier ? item.unique_identifier : 'NA' }}
                      </span>
                    </td>
                    <td class="px-3">
                      <div>
                         <span class="badge badge-info mr-1 " v-if="item.is_account_holder">
                            Enroller
                          </span>
                          <span class="badge badge-info mr-1 " v-if="item.is_examiner">
                            Examiner
                          </span>

                          <span class="badge badge-info mr-1 " v-if="item.is_online_marker">
                            Online Marker
                          </span>
                          <span class="badge badge-info mr-1 " v-if="item.is_supervisor">
                            Supervisor
                          </span>
                      </div>
                    </td>
                    <td class="px-3">
                      <span  class=" mb-1" v-copy="item.email">
                        {{item.email ? item.email : 'NA' }}
                      </span>
                    </td>
                    <td class="text-left">
                      <span  class=" mb-1" v-copy="item.preferred_phone" >
                        {{item.preferred_phone}}
                      </span>
                    </td>

                    <td>
                      <span class="" v-copy="item.score_name">
                        {{item.score_name ? item.score_name : 'NA' }}
                      </span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editAccountHolder(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit enroller</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="loginAsAccountHolder(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-user-lock"></i>
                                </span>
                                <span class="navi-text">Log in as an enroller</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="users.length == 0">
                    <td colspan="7" class="text-center">
                      <strong>No information on user available</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  :disabled="isLoading"
                  v-if="users.length > 0"
                  class="pull-right mt-7"
                  @input="searchUser"
                  v-model="user_page"
                  :total-rows="user_total"
                  :per-page="user_perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import CandidateService from "@/services/candidate/CandidateService";
import ExaminerService from "@/services/user/examiner/ExaminerService";
import AccountholderService from "@/services/user/accountholder/AccountholderService";

const examiner=new ExaminerService();
const candidate=new CandidateService();
const accountholder=new AccountholderService();
export default {
  data(){
    return{
      loading:false,
      isLoading:false,
      candidates:[],
      users:[],
      candidate_page: null,
      user_page: null,
      candidate_total: null,
      user_total: null,
      candidate_perPage: null,
      user_perPage: null,
    }
  },
  mounted() {
      this.searchCandidate();
      this.searchUser();
    },
  methods:{
    getCandidateSummary(examKey){
      this.$tabs.open({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },
    viewAccountHolderInfo(accountHolderId) {
      this.$router.push({
        name: 'accountholder-edit',
        params: {accountholderId: accountHolderId}
      })
    },
    editAccountHolder(accountholderId){
      this.$router.push({
        name: "accountholder-edit",
        params: { accountholderId: accountholderId }
      });
    },
    loginAsAccountHolder(accountHolderId)
    {
      accountholder.loginAsAccountHolder(accountHolderId).then(response=>{
        if(response.data.status=='OK')
        {
          let popup =  window.open(response.data.url,'_blank');
          if (!popup) {
            this.$snotify.warning('Please enable popup');
          }
        }
        else
          this.$snotify.error("Oops something went wrong");
      }).catch(error=>{

      });
    },
    searchUser(){
      this.isLoading=true;
        examiner
            .searchAllUser({"info":this.info},this.user_page)
            .then(response => {
              this.users = response.data.data;

              this.user_page = response.data.meta.current_page;
              this.user_total = response.data.meta.total;
              this.user_perPage = response.data.meta.per_page;
            })
            .catch((err) => {
              this.$snotify.error('Oops looks like something went wrong.');


            })
            .finally(()=>{
              this.isLoading = false;
            });
    },
    searchCandidate(){
      this.loading = true;
        candidate
            .searchAll({'info':this.info},this.candidate_page)
            .then(response => {
              this.candidates = response.data.data;
              this.candidate_page = response.data.meta.current_page;
              this.candidate_total = response.data.meta.total;
              this.candidate_perPage = response.data.meta.per_page;
            })
            .catch((err) => {
              this.$snotify.error('Oops looks like something went wrong.');


            })
            .finally(()=>{
              this.loading = false;
            });
    },

  },
  computed:{
    info() {
      return this.$route.params.info;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
  }
}
</script>